import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Directive, ElementRef, Input } from '@angular/core';
import { DestroyableComponent } from '@models/destroyable.component';
import { take } from 'rxjs';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

declare const google: any;

@Directive({
  selector: 'asl-google-signin-button',
})
export class GoogleSigninButtonDirective extends DestroyableComponent {
  @Input()
  type: 'icon' | 'standard' = 'icon';

  @Input()
  size: 'small' | 'medium' | 'large' = 'medium';

  @Input()
  text: 'signin' | 'signup_with' = 'signin';

  @Input()
  shape: 'square' | 'circle' | 'pill' | 'rectangular' = 'circle';

  @Input()
  theme: 'outline' | 'filled_blue' | 'filled_black' = 'outline';

  @Input()
  logo_alignment: 'left' | 'center' = 'center';

  @Input()
  width = '';

  @Input()
  locale = 'En';

  constructor(
    public errorNotificationService: ErrorNotificationService,
    el: ElementRef,
    socialAuthService: SocialAuthService,
  ) {
    super();
    socialAuthService.initState.pipe(take(1)).subscribe({
      next: () => {
        Promise.resolve(this.width).then((value) => {
          if (value > '400' || (value < '200' && value != '')) {
            Promise.reject(
              'Please note .. max-width 400 , min-width 200 ' +
                '(https://developers.google.com/identity/gsi/web/tools/configurator)',
            );
          } else {
            google.accounts.id.renderButton(el.nativeElement, {
              type: this.type,
              size: this.size,
              text: this.text,
              width: this.width,
              shape: this.shape,
              theme: this.theme,
              logo_alignment: this.logo_alignment,
              locale: this.locale,
            });
          }
        });
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      }
    });
  }
}
